<template>
<!-- 專業生產 -->
  <div class="professional" id="Professional">
    <Header/>
    <!-- 專業生產 - banner -->
    <div class="other__narrow__banner" id="Process">
      <img  :src="'https://www.elevator.com.vn/upload/' + item.professional.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4 v-html="item.professional.title"></h4>
        </div>
      </div>
    </div>
    <!-- 專業生產 描述 -->
    <div class="professional_section_wrapper">
      <div class="container">
        <div class="professional-text">
          <p v-html="item.professionalProcess.descript">
            </p>
        </div>
      </div>
    </div>
    <!-- 專業生產 流程 -->
    <div class="professional_order">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2 class="professional_order-title" v-html="item.professionalProcess.title"></h2>
        </div>
        <div class="">
          <div class="professional-pc-pic">
            <img  :src="'/upload/' + item.professionalProcess.image" alt="" />
          </div>
          <div class="professional-mobile-pic">
            <img  :src="'/upload/' + item.professionalProcess.image1" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 產品企劃 -->
    <div class="professional_section_wrapper_one">
      <div class="container"  v-html="item.professionalProcess.content">
      </div>
    </div>
    <!-- 專業生產 中間banner -->
    <div class="professional_swtich" id="Promise">
      <div class="professional_mobile_banner">
        <h2  v-html="item.professionalPromise.banner"></h2>
        <div class="professional_mobile_banner_pic">
          <img :src="'https://www.elevator.com.vn/upload/' + item.professionalPromise.image1" alt="">
        </div>
      </div>
      <div class="professional_pc_banner">
        <h2  v-html="item.professionalPromise.banner"></h2>
        <div class="professional_pic_banner_pic">
          <img :src="'https://www.elevator.com.vn/upload/' + item.professionalPromise.image" alt="" />
        </div>
      </div>

    </div>
    <!-- 專業生產 中間描述 -->
    <div class="professional_other_block">
      <div class="container">
        <div class="professional_other_block_content">
          <p  v-html="item.professionalPromise.descript">
            </p>
        </div>
      </div>
    </div>
    <!-- 專業生產 專業服務・客戶滿意 -->
    <div class="professional_section_wrapper">
      <div class="container"  v-html="item.professionalPromise.content">
 
      </div>
    </div>
    <!-- 專業生產 下方banner -->
    <div class="other__banner">
      <img :src="'https://www.elevator.com.vn/upload/' + item.professionalQuality.image" alt="" />
      <div class="other__banner__title">
        <h2>{{item.professionalQuality.banner}}</h2>
      </div>
    </div>
    <!-- 公司經營理念 -->
    <div class="professional_section_wrapper box-shadow professional_margin">
      <div class="container">
        <div class="other__ck__title">
          <h2>{{item.professionalQualityBlock1.title}}</h2>
        </div>
        <div class="other__ck__img professional__block">
          <div class="professional_pic_wrapper">
            <img :src="'https://www.elevator.com.vn/upload/' + item.professionalQualityBlock1.image"  alt="" />
          </div>
        </div>
        <div class="professional-operating"  v-html="item.professionalQualityBlock1.content">
      
        </div>
      </div>
    </div>
    <!-- 品質政策 -->
    <div class="professional_section_wrapper professional_margin" id="Quality">
      <div class="container">
        <div class="other__ck__title">
          <h2>{{item.professionalQualityBlock2.title}}</h2>
        </div>
        <div class="other__ck__img professional__block">
          <div class="professional_pic_wrapper">
            <img src="../assets/pic/professional_img_6.png" alt="" />
          </div>
        </div>
        <div class="professional-operating"  v-html="item.professionalQualityBlock2.content">
        
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import Header from "./../components/Header";
import { apiProfessional } from "@/api/pages.js";
export default {
  name: "About",
  components: {
    Header,
  },
  data() {
    return {
      hash: "",
      item: {},
    };
  },
  created() {
    this.getData()
    this.getHashValue()
  },
  mounted() {
    setTimeout( () => {
      const target = $(`${this.hash}`).offset().top
      $(window).scrollTop(target)
    }, 100)
  },
  methods: {
    async getData(){
      try{
        const res = await apiProfessional();
        this.item = res.data;
        console.log(this.item)
        console.log(this.item.professionalPromise)
      }
      catch{
        console.log('err')
      }
    },
    getHashValue(){
      if(this.$route.hash){
        this.hash = this.$route.hash
      }else{
        this.hash = `#${this.$route.name}`
      }
    }
  },
};
</script>
